import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ThemeProvider from "@material-ui/styles/ThemeProvider"
import theme from "../theme"
import Typography from "@material-ui/core/Typography"
import Accordion from "../components/accordion"

const Comorbidites = () => (
  <React.Fragment>
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO title="Comorbidités" />
        <Typography variant="h4" color="inherit" style={{ marginBottom: 20 }}>
          Gestion des Comorbidités et Thérapeutiques
        </Typography>
        <Accordion
          entries={[
            {
              id: "généralités",
              header: "Généralités",
              element: (
                <div>
                  <Typography variant="body1" color="inherit">
                    Les patients sont systématiquement sous IPP pendant 1 mois
                    après la chirurgie. Ce traitement est à poursuivre en cas
                    d’antécédent d’ulcère gastro-duodénal, de tabagisme actif,
                    ou de traitement gastrotoxique au long cours.
                  </Typography>
                  <Typography
                    variant="body1"
                    color="inherit"
                    style={{ marginTop: 20 }}
                  >
                    Il faut penser à adapter les posologies des traitements en
                    cours, la chirurgie malabsorptive pouvant diminuer
                    l’absorption de certains médicaments (AVK, hormones
                    thyroïdiennes, antiépileptiques, etc.).
                  </Typography>
                  <Typography
                    variant="body1"
                    color="inherit"
                    style={{ marginTop: 20 }}
                  >
                    Les médicaments gastrotoxiques (aspirine, AINS, corticoïdes,
                    etc.) doivent être évités dans la mesure du possible.
                  </Typography>
                </div>
              ),
            },
            {
              id: "diabete",
              header: "Diabète de type 2",
              element: (
                <div>
                  <Typography variant="body1" color="inherit">
                    Une adaptation des traitements anti-diabétique est
                    nécessaire après une chirurgie bariatrique :
                  </Typography>
                  <Typography
                    variant="body1"
                    color="inherit"
                    style={{ marginTop: 20 }}
                    component="span"
                  >
                    <ul>
                      <li>
                        <strong>Biguanides: </strong>la posologie initiale est
                        poursuivie après la chirurgie bariatrique, le traitement
                        est à adapter ensuite et peut être arrêté en cas de
                        rémission.
                      </li>
                      <li>
                        <strong>
                          Sulfamides hypoglycémiants et Glinides:{" "}
                        </strong>
                        leur arrêt s’impose après la chirurgie bariatrique.
                      </li>
                      <li>
                        <strong>Inhibiteurs des α-glucosidases: </strong>leur
                        arrêt s’impose après la chirurgie bariatrique.
                      </li>
                      <li>
                        <strong>Inhibiteurs des DPP4: </strong>la posologie
                        initiale est poursuivie après la chirurgie bariatrique,
                        le traitement est à adapter ensuite et peut être arrêté
                        en cas de rémission.
                      </li>
                      <li>
                        <strong>Analogues du GLP-1: </strong>leur arrêt s’impose
                        après la chirurgie bariatrique.
                      </li>
                      <li>
                        <strong>Insulines: </strong>
                        <ul>
                          <li>
                            <i>Analogues lents: </i> la dose totale quotidienne
                            est diminuée de moitié, le traitement est à adapter
                            ensuite et peut être arrêté en cas de rémission.
                          </li>
                          <li>
                            <i>Analogues rapides: </i>leur arrêt s’impose après
                            la chirurgie bariatrique.
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <i>
                      Référence :{" "}
                      <a href="/protocole-diabete-chirugie-2019.pdf" target="_blank">
                        Protocole du CHU de Poitiers sur l’adaptation
                        des traitements anti-diabétiques au décours de la
                        chirurgie bariatrique.
                        </a>
                    </i>
                  </Typography>
                </div>
              ),
            },
            {
              id: "hta",
              header: "HTA, dyslipidémie",
              element: (
                <div>
                  <Typography variant="body1" color="inherit">
                    Attention car l’effet de la perte de poids peut être
                    transitoire : si arrêt des traitements, surveillance.
                  </Typography>
                </div>
              ),
            },
            {
              id: "saos",
              header: "SAOS",
              element: (
                <div>
                  <Typography variant="body1" color="inherit">
                    En cas de rémission, le traitement peut être arrêté.
                  </Typography>
                </div>
              ),
            },
          ]}
        />
      </Layout>
    </ThemeProvider>
  </React.Fragment>
)

export default Comorbidites
